import { render, staticRenderFns } from "./SendHire.vue?vue&type=template&id=126cca44&scoped=true&"
import script from "./SendHire.vue?vue&type=script&lang=js&"
export * from "./SendHire.vue?vue&type=script&lang=js&"
import style0 from "./SendHire.vue?vue&type=style&index=0&id=126cca44&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "126cca44",
  null
  
)

export default component.exports